
import {Component, Vue} from 'vue-property-decorator';
import Axios from 'axios';

@Component({})
export default class Version extends Vue {
	public version: string = '';
	public date: string = '';

	/**
	 * hook qui est appelle avant de rendre la page
	 */
	private created(): void {
		const buildPage = (response: any) => {
			this.version = response.data.version;
			this.date = response.data.date;
		};
		Axios.get('/version.json').then(buildPage);
	}

}
